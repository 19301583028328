import { Col, LightText, Row } from '@carafe/components';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  Avatar as ChakraAvatar,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  useToast,
} from '@chakra-ui/react';
import { GetProfileQuery, useRemoveAvatarMutation } from '@generated/graphql';
import { strings } from '@localisation';
import { useEffect, useState } from 'react';
import { IoCreateOutline, IoTrashOutline } from 'react-icons/io5';

import { Initials } from './Initials';
import { PhotoPicker } from './PhotoPicker';

type Props = Pick<
  GetProfileQuery['getProfile'],
  'avatar' | 'firstName' | 'lastName'
>;

export const Avatar = ({ avatar, firstName, lastName }: Props): JSX.Element => {
  const [first, last] = [firstName?.charAt(0), lastName?.charAt(0)];
  const initials = first && last ? `${first}${last}` : undefined;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [menuOpen, setMenuOpen] = useState(false);
  const open = () => setMenuOpen(!menuOpen);
  const close = () => setMenuOpen(false);
  const [{ data }, removeAvatar] = useRemoveAvatarMutation();
  const toast = useToast();

  useEffect(() => {
    if (data?.removeAvatar) {
      toast({
        title: strings.toast.title,
        description: strings.toast.description,
        status: 'success',
      });
    }
  }, [data?.removeAvatar, toast]);

  return (
    <>
      <Row mb="1rem" alignItems="center">
        {avatar ? (
          <ChakraAvatar height="5rem" width="5rem" src={avatar} />
        ) : (
          <Initials initials={initials} />
        )}
        <Popover placement="bottom-start" isOpen={menuOpen} onClose={close}>
          <div>
            <PopoverTrigger>
              <Button
                colorScheme="teal"
                variant="link"
                color="teal.600"
                fontSize="0.875rem"
                my="auto"
                onClick={open}
              >
                Edit
              </Button>
            </PopoverTrigger>
          </div>
          <PopoverContent maxW="10rem" px="0.25rem" py="0.5rem">
            <PopoverArrow />
            <Col>
              <Row
                alignItems="center"
                py="0.25rem"
                ps="0.625rem"
                onClick={onOpen}
                cursor="pointer"
              >
                <IoCreateOutline />
                <LightText
                  ms="0.375rem"
                  fontSize="0.875rem"
                  text={strings.avatar.addPhoto}
                />
              </Row>
              <Row
                ps="0.625rem"
                color="red.500"
                bg="red.50"
                alignItems="center"
                py="0.25rem"
                onClick={() => {
                  removeAvatar({});
                  close();
                }}
                cursor="pointer"
              >
                <IoTrashOutline size="1rem" />
                <LightText
                  ms="0.375rem"
                  fontSize="0.875rem"
                  text={strings.avatar.removePhoto}
                />
              </Row>
            </Col>
          </PopoverContent>
        </Popover>
      </Row>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent borderRadius="4px" maxWidth="22.5rem">
          <PhotoPicker onClose={onClose} />
        </ModalContent>
      </Modal>
    </>
  );
};
